import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/IndexAdm/Home';
import Clients from '../pages/IndexAdm/Client/Clients';
import UpdateClient from '../pages/IndexAdm/Client/UpdateClient';
import CreateClient from '../pages/IndexAdm/Client/CreateClient';
import { AccessGroups } from '../pages/IndexAdm/AccessGroup/AccessGroups';
import { AccessGroupAdd } from '../pages/IndexAdm/AccessGroup/AccessGroupAdd';
import { AccessGroupEdit } from '../pages/IndexAdm/AccessGroup/AccessGroupEdit';
import { AccessGroupsUsers } from '../pages/IndexAdm/AccessGroup/AccessGroupsUsers';
import UsersByClient from '../pages/IndexAdm/User/UsersByClient';
import Users from '../pages/IndexAdm/User/Users';
import { UserAdd } from '../pages/IndexAdm/User/UserAdd';
import { UserEdit } from '../pages/IndexAdm/User/UserEdit';
import Products from '../pages/IndexAdm/Academy/Product/Products';
import { ProductEdit } from '../pages/IndexAdm/Academy/Product/ProductEdit';
import { ProductAdd } from '../pages/IndexAdm/Academy/Product/ProductAdd';
import Categories from '../pages/IndexAdm/Academy/Category/Categories';
import CategoryEdit from '../pages/IndexAdm/Academy/Category/CategoryEdit';
import CategoryAdd from '../pages/IndexAdm/Academy/Category/CategoryAdd';
import { CategoryUsers } from '../pages/IndexAdm/Academy/Category/CategoriesUsers';
import CategoriesByProduct from '../pages/IndexAdm/Academy/Category/CategoriesByProduct';
import SubCategories from '../pages/IndexAdm/Academy/SubCategory/SubCategories';
import SubCategoryEdit from '../pages/IndexAdm/Academy/SubCategory/SubCategoryEdit';
import SubCategoryAdd from '../pages/IndexAdm/Academy/SubCategory/SubCategoryAdd';
import SubCategoriesByCategory from '../pages/IndexAdm/Academy/SubCategory/SubCategoriesByCategory';
import { Logs } from '../pages/IndexAdm/Logs';
import Post from '../pages/IndexAdm/Academy/Posts/Posts';
import PostEdit from '../pages/IndexAdm/Academy/Posts/PostEdit';
import PostAdd from '../pages/IndexAdm/Academy/Posts/PostAdd';
import PostsBySubCategory from '../pages/IndexAdm/Academy/Posts/PostsBySubCategory';
import Videos from '../pages/IndexAdm/Academy/Video/Videos';
import { VideoAddIndex } from '../pages/IndexAdm/Academy/Video/VideoAdd';
import VideoEdit from '../pages/IndexAdm/Academy/Video/VideoEdit';
import Comments from '../pages/IndexAdm/Academy/Comments';
import NotFound from '../pages/NotFound';
import About from '../pages/About';
import Settings from '../pages/Settings';
import EditPassword from '../pages/Settings/EditPassword';
import { B2BDatabaseAddList } from '../pages/IndexAdm/B2BDatabases/B2BDatabasesAddList';
import { B2BDatabaseSearchIndex } from '../pages/IndexAdm/B2BDatabases/B2BDatabasesSearch';
import { B2BDatabaseLog } from '../pages/IndexAdm/B2BDatabases/B2BDatabasesLog';
import { B2BDatabaseSettings } from '../pages/IndexAdm/B2BDatabases/B2BDatabasesSettings';

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/clients/add" element={<CreateClient />} />
      <Route path="/clients/:id" element={<UpdateClient />} />
      <Route path="/access-group" element={<AccessGroups />} />
      <Route path="/access-group/:id" element={<AccessGroupEdit />} />
      <Route path="/access-group/add" element={<AccessGroupAdd />} />
      <Route path="/access-group/users/:id" element={<AccessGroupsUsers />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/client/:id" element={<UsersByClient />} />
      <Route path="/users/add" element={<UserAdd />} />
      <Route path="/users/add/client/:id" element={<UserAdd />} />
      <Route path="/users/edit/:idUser" element={<UserEdit />} />
      <Route path="/products" element={<Products />} />
      <Route path="/products/:id" element={<ProductEdit />} />
      <Route path="/products/add" element={<ProductAdd />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/categories/:id" element={<CategoryEdit />} />
      <Route path="/categories/add" element={<CategoryAdd />} />
      <Route path="/categories/users/:id" element={<CategoryUsers />} />
      <Route path="/categories/by-product/:id" element={<CategoriesByProduct />} />
      <Route path="/sub-categories" element={<SubCategories />} />
      <Route path="/sub-categories/:id" element={<SubCategoryEdit />} />
      <Route path="/sub-categories/add" element={<SubCategoryAdd />} />
      <Route path="/sub-categories/by-category/:id" element={<SubCategoriesByCategory />} />
      <Route path="/posts/" element={<Post />} />
      <Route path="/posts/:id" element={<PostEdit />} />
      <Route path="/posts/add" element={<PostAdd />} />
      <Route path="/posts/by-sub-category/:id" element={<PostsBySubCategory />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/videos/add" element={<VideoAddIndex />} />
      <Route path="/videos/edit/:id" element={<VideoEdit />} />
      <Route path="/comments" element={<Comments />} />
      <Route path="/logs" element={<Logs />} />
      <Route path="/about" element={<About />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/settings/edit-password" element={<EditPassword />} />
      <Route path="/b2b-databases/search" element={<B2BDatabaseSearchIndex />} />
      <Route path="/b2b-databases/add" element={<B2BDatabaseAddList />} />
      <Route path="/b2b-databases/logs" element={<B2BDatabaseLog />} />
      <Route path="/b2b-databases/settings" element={<B2BDatabaseSettings />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
