import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { SideBarVideosContext } from '../../../context/SideBarVideosContext';
import VideosWatchedService from '../../../services/VideosWatched';
import { Button } from '../../Button';
import { Translate } from './translate';

export function ButtonFinish({ idVideo }) {
  const translate = Translate();
  const { product } = useContext(SideBarVideosContext);
  // const [isBtnDisbled, setBtnDisabled] = useState(true);
  const [userFinishedVideo, setUserFinishedVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    async function init() {
      await checkIfUserFinishVideo();
    }

    init();
    setIsLoading(false);
  }, []);

  async function handleFinishVideo() {
    try {
      if (userFinishedVideo) {
        await VideosWatchedService.delete({ id: idVideo });
        setUserFinishedVideo(false);
      }

      if (!userFinishedVideo) {
        await VideosWatchedService.store({ idVideo });
        setUserFinishedVideo(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function checkIfUserFinishVideo() {
    const registered = await VideosWatchedService.findOne({ id: idVideo });
    if (registered) {
      setUserFinishedVideo(true);
    } else {
      setUserFinishedVideo(false);
    }
  }

  return (
    <Button
      color={userFinishedVideo ? 'ghost' : product.colorName}
      onClick={handleFinishVideo}
    >
      {isLoading && 'Loading....'}
      {!isLoading && (userFinishedVideo ? translate.btnToFinish : translate.btnFinished)}
    </Button>
  );
}

ButtonFinish.propTypes = {
  idVideo: PropTypes.number,
};


