
import React, { useEffect, useState } from 'react';

import B2BDatabaseSectorService from '../../../../services/B2BDatabaseSector';
import B2BDatabaseTechnologyService from '../../../../services/B2BDatabaseTechnology';
import B2BDatabaseAnnualRevenueService from '../../../../services/B2BDatabaseAnnualRevenue';
import B2BDatabaseEmployeesQuantityService from '../../../../services/B2BDatabaseEmployeesQuantity';
import B2BDatabaseHeadquartersRegionService from '../../../../services/B2BDatabaseHeadquartersRegion';

import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import Loader from '../../../../components/Loader';
import { NoDataMessage } from '../../../../components/NoDataMessage';
import { Button } from '../../../../components/Button';

import toast from '../../../../helpers/toast';

import { Translate } from './translate';
import { ContainerInputs, ContainerInput, ContainerResults } from './style';

export function B2BDatabaseSettings() {
  const translate = Translate();
  const [optionSelected, setOptionSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState({
    sector: [],
    technology: [],
    employeesQuantity: [],
    annualRevenue: [],
    headquartersRegion: [],
  });
  const [description, setDescription] = useState('');

  useEffect(() => {
    getDatas();

    return () => {
      setList({
        sector: [],
        technology: [],
        employeesQuantity: [],
        annualRevenue: [],
        headquartersRegion: [],
      });
    };
  }, []);


  async function getDatas() {
    setIsLoading(true);
    try {
      const [
        dataSector,
        dataTechnology,
        dataAnnualRevenue,
        dataEmployeesQuantity,
        dataHeadquartersRegion,
      ] = await Promise.allSettled([
        B2BDatabaseSectorService.findAll(),
        B2BDatabaseTechnologyService.findAll(),
        B2BDatabaseAnnualRevenueService.findAll(),
        B2BDatabaseEmployeesQuantityService.findAll(),
        B2BDatabaseHeadquartersRegionService.findAll(),
      ]);

      setList((prevState) => ({
        ...prevState,
        sector: dataSector?.value.map((item) => ({ id: item.id, name: item.name })),
        technology: dataTechnology?.value.map((item) => ({ id: item.id, name: item.name })),
        annualRevenue: dataAnnualRevenue?.value.map((item) => ({ id: item.id, name: item.name })),
        employeesQuantity: dataEmployeesQuantity?.value.map((item) => ({ id: item.id, name: item.name })),
        headquartersRegion: dataHeadquartersRegion?.value.map((item) => ({ id: item.id, name: item.name })),
      }));
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmmit() {
    try {
      if (optionSelected === 'sector') await B2BDatabaseSectorService.store({ name: description });
      if (optionSelected === 'technology') await B2BDatabaseTechnologyService.store({ name: description });
      if (optionSelected === 'employeesQuantity') await B2BDatabaseEmployeesQuantityService.store({ name: description });
      if (optionSelected === 'annualRevenue') await B2BDatabaseAnnualRevenueService.store({ name: description });
      if (optionSelected === 'headquartersRegion') await B2BDatabaseHeadquartersRegionService.store({ name: description });
      toast('success', 'Data added successfully');
    } catch (error) {
      toast('danger', error.message);
    } finally {
      setDescription('');
      await getDatas();
    }
  }

  return (
    <>
      <TitlePage title="B2B Database - Settings" />
      <ContainerInputs>
        <ContainerInput>
          <label htmlFor="option">{translate.labelOption}</label>
          <select
            id="option"
            value={optionSelected}
            onChange={(event) => setOptionSelected(event.target.value)}
          >
            <option value="">Selecione</option>
            <option value="sector">Setor</option>
            <option value="technology">Tecnologia</option>
            <option value="employeesQuantity">Quantidade de funcionários</option>
            <option value="annualRevenue">Receita anual</option>
            <option value="headquartersRegion">Região da sede</option>
          </select>
        </ContainerInput>
        <ContainerInput>
          <label htmlFor="description">{translate.labelDescription}</label>
          <input
            id="description"
            type="text"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </ContainerInput>
        <Button
          color="white"
          onClick={handleSubmmit}
          disabled={(!optionSelected || description.length < 2)}
        >
          {translate.btnSubmit}
        </Button>
      </ContainerInputs>
      <ContainerResults>
        <hr />
        {(isLoading) && <Loader loading={isLoading} />}
        {(!list[optionSelected] && !isLoading) && <NoDataMessage>{translate.messageList}</NoDataMessage>}
        {(list[optionSelected]?.length === 0 && !isLoading) && <NoDataMessage>{translate.messageNoResults}</NoDataMessage>}
        {(list[optionSelected] && list[optionSelected]?.length > 0 && !isLoading) && (
          <>
            <h3>{translate.title}</h3>
            {list[optionSelected]?.map((item) => (
              <Square size="medium" key={item.id}>
                <span>{item.name}</span>
              </Square>
            ))}
          </>
        )}
      </ContainerResults>
    </>
  );
}

