import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext/index';
import { SideBarVideosContext } from '../../context/SideBarVideosContext';

import Arrow from '../../styles/logos/arrow.svg';
import ArrowMenu from '../../styles/logos/arrow_menu.svg';
import UserEngine from '../../styles/logos/user_engine.svg';
import Info from '../../styles/logos/info.svg';
import Logout from '../../styles/logos/logout.svg';

import { Translate } from './translate';
import {
  ContainerList, ActionsContainer, ButtonMetting, UlUser, NavContainer,
} from './styles';

function Menu({ onHandleToogle, toogle }) {
  const translate = Translate();
  const { handleLogout, userInfo: { firstName, email } } = useContext(AuthContext);
  const sidebarVideoContext = useContext(SideBarVideosContext);
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = window.location;
  const pathNameLocal = pathname.split('/')[1];

  function handleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <NavContainer $toogle={toogle}>
      <img src={ArrowMenu} alt="Arrow" onClick={onHandleToogle} />
      <ActionsContainer>
        {email === 'suporte+111menu@shifters.com.br' && (
        <ButtonMetting
          to="https://calendly.com/fredvilaverde/15min"
          target="_blank"
          color={sidebarVideoContext.product?.colorName}
        >
          <span>
            {translate.scheduleMetting}
          </span>
        </ButtonMetting>
        )}
        <UlUser onClick={handleMenu}>
          <div className="c-user">
            <span className="c-user__name">{firstName}</span>
            <span className="c-user__email">{email}</span>
          </div>
          <img src={Arrow} alt="Seta" />
          <ContainerList $showmenu={showMenu}>
            <Link to={`/${pathNameLocal}/settings`}>
              <li>
                <img src={UserEngine} alt="User's settings" />
                <span>{translate.settings}</span>
              </li>
            </Link>
            <Link to={`/${pathNameLocal}/about`}>
              <li>
                <img src={Info} alt="User's settings" />
                <span>{translate.about}</span>
              </li>
            </Link>
            <Link to="/" onClick={handleLogout}>
              <li>
                <img src={Logout} alt="Logout" />
                <span>Logout</span>
              </li>
            </Link>
          </ContainerList>
        </UlUser>
      </ActionsContainer>
    </NavContainer>
  );
}

Menu.defaultProps = {
  onHandleToogle: PropTypes.func,
  toogle: false,
};

Menu.propTypes = {
  onHandleToogle: PropTypes.func,
  toogle: PropTypes.bool,
};

export default Menu;
