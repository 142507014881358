import {
  useEffect, createContext, useMemo, useState,
} from 'react';
import B2BDatabaseFiltersService from '../../../services/B2BDatabaseFilters';
import { Translate } from './translate';

const B2BDataBaseFilterContext = createContext();

function B2BDataBaseFilterProvider({ children }) {
  const translate = Translate();
  const [filtersList, setFiltersList] = useState({
    position: [],
    country: [],
    state: [],
    city: [],
    sector: [],
    company: [],
    searchFilter: '',
    typeBase: '',
  });
  const [filterSelected, setFilterSelected] = useState({
    position: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    city: [],
    state: [],
    country: [],
    sector: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    company: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    searchFilter: '',
    typeBase: '',
  });
  const [filterApplied, setFilterApplied] = useState({
    position: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    city: [],
    state: [],
    country: [],
    sector: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    company: {
      exact: [],
      contains: [],
      exactNegative: [],
      containsNegative: [],
    },
    searchFilter: '',
    typeBase: '',
  });
  const [searchTerms, setSearchTerms] = useState({
    position: '',
    sector: '',
    company: '',
  });
  const [isLoading, setIsLoading] = useState({
    position: false,
    sector: false,
    company: false,
  });
  const [filterDescription, setFilterDescription] = useState(translate.selectedFilter);
  const [filterPreSelectedFilter, setFilterPreSelectedFilter] = useState(true);
  const [handleClickApply, setHandleClickApply] = useState(false);

  useEffect(() => {
    getRegionsList();
  }, []);

  useEffect(() => {
    updateStatesAndCitiesList();
  }, [filterSelected.country]);

  useEffect(() => {
    updateCitiesList();
  }, [filterSelected.state]);

  // Fill the list of position's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.position) {
        setFiltersList((prevState) => ({ ...prevState, position: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, position: true }));
        const list = await B2BDatabaseFiltersService.findPositions({
          searchTerm: searchTerms.position,
          hideTerms: filterSelected.position.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, position: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, position: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, position: [] }));
    };
  }, [searchTerms.position]);

  // Fill the list of sector's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.sector) {
        setFiltersList((prevState) => ({ ...prevState, sector: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, sector: true }));
        const list = await B2BDatabaseFiltersService.findSector({
          searchTerm: searchTerms.sector,
          hideTerms: filterSelected.sector.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, sector: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, sector: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, sector: [] }));
    };
  }, [searchTerms.sector]);

  // Fill the list of company's filter list
  useEffect(() => {
    (async () => {
      if (!searchTerms.company) {
        setFiltersList((prevState) => ({ ...prevState, company: [] }));
        return;
      }
      try {
        setIsLoading((prevState) => ({ ...prevState, company: true }));
        const list = await B2BDatabaseFiltersService.findCompany({
          searchTerm: searchTerms.company,
          hideTerms: filterSelected.company.exact,
        });
        setFiltersList((prevState) => ({ ...prevState, company: list }));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading((prevState) => ({ ...prevState, company: false }));
      }
    })();

    return () => {
      setFiltersList((prevState) => ({ ...prevState, company: [] }));
    };
  }, [searchTerms.company]);

  // Change filter name
  useEffect(() => {
    setHandleClickApply(false);
    setFilterDescription(translate.selectedFilter);
    setFilterPreSelectedFilter(true);
  }, [filterSelected]);

  function applyFilter() {
    setFilterApplied(filterSelected);
    setFilterDescription(translate.appliedFilter);
    setFilterPreSelectedFilter(false);
    setHandleClickApply(true);
  }

  async function getRegionsList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filtersList.country,
        state: filterSelected.state,
        city: filterSelected.city,
      });
      setFiltersList((prevState) => ({
        ...prevState,
        country: regions.country,
        state: regions.state,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateStatesAndCitiesList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filterSelected.country,
        state: [],
        city: [],
      });
      setFiltersList((prevState) => ({
        ...prevState,
        state: regions.state,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCitiesList() {
    try {
      const regions = await B2BDatabaseFiltersService.findRegions({
        country: filterSelected.country,
        state: filterSelected.state,
        city: [],
      });
      setFiltersList((prevState) => ({
        ...prevState,
        city: regions.city,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  function updateFilterSelectedExact(key, item) {
    // Includes value at selected exact list
    const newFilterList = JSON.parse(JSON.stringify(filterSelected));
    newFilterList[key].exact.push(item);
    setFilterSelected(newFilterList);

    // Delete value at filter list
    const newArr = filtersList[key].filter((it) => it !== item);
    setFiltersList((prevState) => ({ ...prevState, [key]: newArr }));
  }

  function updateFilterSelected({ key, subKey, value }) {
    // Includes value at selected exact list
    const newFilterList = JSON.parse(JSON.stringify(filterSelected));
    newFilterList[key][subKey].push(value);
    setFilterSelected(newFilterList);
  }

  function deleteOneItemFromSelectedFilters(key, subKey, value) {
    const arrayDeletedValue = filterSelected[key][subKey].filter((item) => item !== value);
    const newObj = { ...filterSelected, [key]: { ...filterSelected[key], [subKey]: arrayDeletedValue } };
    setFilterSelected(newObj);
  }

  function deleteAllItemsFromSelectedFilters(key, subKey) {
    const newObj = { ...filterSelected, [key]: { ...filterSelected[key], [subKey]: [] } };
    setFilterSelected(newObj);
  }

  function clearAllLists(key) {
    setFilterSelected((prevState) => ({
      ...prevState,
      [key]: {
        exact: [],
        contains: [],
        exactNegative: [],
        containsNegative: [],
      },
    }));
  }

  const contextValues = useMemo(() => ({
    filtersList,
    filterSelected,
    filterApplied,
    setFilterSelected,
    filterPreSelectedFilter,
    setSearchTerms,
    isLoading,
    updateFilterSelectedExact,
    updateFilterSelected,
    deleteOneItemFromSelectedFilters,
    deleteAllItemsFromSelectedFilters,
    clearAllLists,
    filterDescription,
    applyFilter,
    handleClickApply,
  }), [
    filtersList,
    filterSelected,
    filterApplied,
    setFilterSelected,
    filterPreSelectedFilter,
    setSearchTerms,
    isLoading,
    updateFilterSelectedExact,
    updateFilterSelected,
    deleteOneItemFromSelectedFilters,
    deleteAllItemsFromSelectedFilters,
    clearAllLists,
    filterDescription,
    applyFilter,
    handleClickApply,
  ]);

  return (
    <B2BDataBaseFilterContext.Provider value={contextValues}>
      {children}
    </B2BDataBaseFilterContext.Provider>
  );
}

export { B2BDataBaseFilterContext, B2BDataBaseFilterProvider };
