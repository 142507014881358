import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleCommentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smartphone}) {
    flex-direction: column;
    gap: 8px;
  }

  .rating-name {
    font-weight: 500;
  }

  .rating-area {
    display: flex;
    align-items: center;
    gap: 8px;

    .rating-score {
      font-size: 14px;
      font-weight: 500;
      background-color: ${({ theme }) => theme.yellow};
      color: ${({ theme }) => theme.gray1};
      border-radius: 4px;
      padding: 1px 4px;
    }
  }

  .rating-date {
    color: ${({ theme }) => theme.gray4};
    font-size: 13px;
  }


`;

export const Description = styled.div`
  margin-top: 16px;
`;

