import React, { useEffect, useMemo, useState } from 'react';
import ApisService from '../../../services/APIs';
import TitlePage from '../../../components/TitlePage';
import BackButton from '../../../components/BackButton';
import Square from '../../../components/Square';
import Loader from '../../../components/Loader';
import { NoDataMessage } from '../../../components/NoDataMessage';
import { Badge } from '../../../components/Badge';
import Dates_New from '../../../helpers/Dates_New';
import { Container, ContainerSquare } from './style';
import { Translate } from './translate';

export function Apis() {
  const translate = Translate();
  const [apisData, setApisData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    try {
      setIsLoading(true);
      const data = await ApisService.findAllApis();
      setApisData(data);
    } catch {
      setApisData([]);
    } finally {
      setIsLoading(false);
    }
  }

  const [googleAdwordsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'googleAdwords'));
  const [metaAdsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'metaAds'));
  const [linkedinAdsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'linkedinAds'));
  const [tikTokAdsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'tikTokAds'));
  const [googleAnalyticsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'googleAnalytics'));
  const [vtexRevenueInfo] = useMemo(() => apisData?.filter((data) => data.api === 'vtexRevenue'));
  const [vtexClientsInfo] = useMemo(() => apisData?.filter((data) => data.api === 'vtexClients'));
  const [woocommerceInfo] = useMemo(() => apisData?.filter((data) => data.api === 'woocommerce'));
  const [hotmartInfo] = useMemo(() => apisData?.filter((data) => data.api === 'hotmart'));
  const [shopifyInfo] = useMemo(() => apisData?.filter((data) => data.api === 'shopify'));

  return (
    <>
      <TitlePage title="APIs" />
      {isLoading && <Loader loading={isLoading} />}
      {(!isLoading && apisData.length === 0) && <NoDataMessage>{translate.message}</NoDataMessage>}
      {(!isLoading && apisData.length > 0) && (
        <Container>
          <div className="container-apis">
            <div className="container-main-title">
              <h3>1st Level</h3>
              <h4>Midia Data</h4>
            </div>
            <div className="container-apis-cards">
              <Square size="medium">
                <ContainerSquare $status={googleAdwordsInfo?.status}>
                  <div className="container-title">
                    <div className="title">Google Adwords (via GA4)</div>
                    <div className="container-infos">
                      <div>{googleAdwordsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(googleAdwordsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={metaAdsInfo?.status}>
                  <div className="container-title">
                    <div className="title">Meta Ads</div>
                    <div className="container-infos">
                      <div>{metaAdsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(metaAdsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={linkedinAdsInfo?.status}>
                  <div className="container-title">
                    <div className="title">LinkedIn Ads</div>
                    <div className="container-infos">
                      <div>{linkedinAdsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(linkedinAdsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={tikTokAdsInfo?.status}>
                  <div className="container-title">
                    <div className="title">TikTok Ads</div>
                    <div className="container-infos">
                      <div>{tikTokAdsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(tikTokAdsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
            </div>
          </div>
          <div className="container-apis">
            <div className="container-main-title">
              <h3>2nd Level</h3>
              <h4>Site Data</h4>
            </div>
            <div className="container-apis-cards">
              <Square size="medium">
                <ContainerSquare $status={googleAnalyticsInfo?.status}>
                  <div className="container-title">
                    <div className="title">Google Analytics 4</div>
                    <div className="container-infos">
                      <div>{googleAnalyticsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(googleAnalyticsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
            </div>
          </div>
          <div className="container-apis">
            <div className="container-main-title">
              <h3>3rd Level</h3>
              <h4>CRM & Finance Data</h4>
            </div>
            <div className="container-apis-cards">
              <Square size="medium">
                <ContainerSquare $status={vtexRevenueInfo?.status}>
                  <div className="container-title">
                    <div className="title">Vtex Revenue</div>
                    <div className="container-infos">
                      <div>{vtexRevenueInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(vtexRevenueInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={vtexClientsInfo?.status}>
                  <div className="container-title">
                    <div className="title">Vtex Clients</div>
                    <div className="container-infos">
                      <div>{vtexClientsInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(vtexClientsInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={woocommerceInfo?.status}>
                  <div className="container-title">
                    <div className="title">WhooCommerce</div>
                    <div className="container-infos">
                      <div>{woocommerceInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(woocommerceInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={hotmartInfo?.status}>
                  <div className="container-title">
                    <div className="title">Hotmart</div>
                    <div className="container-infos">
                      <div>{hotmartInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(hotmartInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
              <Square size="medium">
                <ContainerSquare $status={shopifyInfo?.status}>
                  <div className="container-title">
                    <div className="title">Shopify</div>
                    <div className="container-infos">
                      <div>{hotmartInfo?.status}</div>
                      <div className="online-offline" />
                    </div>
                  </div>
                  <div>
                    <Badge>{translate.lastUpdated}: {Dates_New.formatDatesMask(shopifyInfo?.lastDate) || 'N/A'}</Badge>
                  </div>
                </ContainerSquare>
              </Square>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
