import styled, { css } from 'styled-components';

export const Nav = styled.nav`
  width: 260px;
  background-color: ${({ theme }) => theme.grayMenu};
  height: 100%;
  position: fixed;
  transition: 0.4s ease-in-out;
  transform: ${({ $toogle }) => ($toogle ? 'translate(0)' : 'translate(-260px)')};
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 98;

  .container-logo {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 180px;
    }
  }

  ul > li {
    padding: 4px 24px;
    list-style: none;
  }

  .menu .sidebar-logo {
    width: 20px;
    height: 20px;
  }

  .menu > div,
  .menu > a {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    text-decoration: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  .sidebar-arrow {
    position: absolute;
    right: 16px;
    transform: rotate(0);
    transition: .4s ;
  }
   .sidebar-arrow.collapse {
    transform: rotate(-180deg);
  }
  .menu > div:hover,
  .menu > a:hover {
    background-color: ${({ theme }) => theme.grayBackground};
  }

  .submenu {
    display: none;
  }
  .submenu.collapse {
    display: flex;
    flex-direction: column;
  }

  .submenu strong {
    padding: 10px 0px 10px 40px;
    color: ${({ theme }) => theme.grayDark};
    font-size: 13px;
    text-transform: uppercase;
  }

  .submenu li {
    list-style: none;
    font-size: 15px;
    padding: 8px 0px 8px 44px;
    border-radius: 4px;
  }
  .submenu li:hover {
    background-color: ${({ theme }) => theme.grayBackground};
  }
  .submenu li a {
    text-decoration: none;
    width: 100%;
    display: inline-block;
  }
  .submenu li a span {
    color: ${({ theme }) => theme.gray5};
  }
  .submenu li a:hover span{
    color: ${({ theme }) => theme.fontWhite};
  }
`;

export const ListAcademy = styled.li`

  a span {
    color: ${({ $productIsSelected, theme, color }) => ($productIsSelected ? theme[color] : theme.gray5)} !important;
  }

  > a:hover > span,
  > a > span:hover,
  > a:focus > span,
  > a > span:focus {
    color: ${({ theme, color }) => theme[color] || theme.fontWhite} !important;
    opacity: 1 !important;
  }
`;
