import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconHouse from '../../styles/logos/icon_house.svg';
import IconClients from '../../styles/logos/clients.svg';
import IconUsersManage from '../../styles/logos/users_manage.svg';
import IconServices from '../../styles/logos/GrServices.svg';
import IconArrow from '../../styles/logos/icon_sidebararrow.svg';
import IconData from '../../styles/logos/GrDatabase.svg';
import IconAccessGroup from '../../styles/logos/access_group.svg';
import IconDownload from '../../styles/logos/download.svg';
import logoShifters from '../../styles/logos/shiftersy.png';
import * as S from '../SidebarUser/styles';

function SideBarAdmin({ toogle }) {
  function handleCollapse(e) {
    let submenuHTML = '';
    let arrowHTML = '';

    if (e.target.classList.contains('sidebar-logo') || e.target.classList.contains('sidebar-name') || e.target.classList.contains('sidebar-arrow')) {
      submenuHTML = e.target.parentElement.nextElementSibling;
    } else {
      submenuHTML = e.target.nextElementSibling;
    }

    if (e.target.classList.contains('sidebar-logo')) {
      arrowHTML = e.target.nextElementSibling.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-name')) {
      arrowHTML = e.target.nextElementSibling;
    } else if (e.target.classList.contains('sidebar-arrow')) {
      arrowHTML = e.target;
    } else {
      arrowHTML = e.target.children[2];
    }

    if (submenuHTML.classList.contains('submenu')) {
      if (submenuHTML.className === 'submenu') {
        submenuHTML.classList.add('collapse');
        arrowHTML.classList.add('collapse');
      } else {
        submenuHTML.className = 'submenu';
        arrowHTML.className = 'sidebar-arrow';
      }
    }
  }

  return (
    <S.Nav $toogle={toogle}>
      <div className="container-logo">
        <Link to="/admin">
          <img src={logoShifters} alt="Logo Shifters" />
        </Link>
      </div>
      <ul>
        <li className="menu">
          <Link to="/admin">
            <img src={IconHouse} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> Home </span>
          </Link>
        </li>
        <li className="menu">
          <Link to="/admin/clients">
            <img src={IconClients} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> Clients </span>
          </Link>
        </li>
        <li className="menu">
          <Link to="/admin/access-group">
            <img src={IconAccessGroup} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> Access </span>
          </Link>
        </li>
        <li className="menu">
          <Link to="/admin/users">
            <img src={IconUsersManage} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> Users </span>
          </Link>
        </li>
        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconServices} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">Academy</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/admin/products">
                <span className="sidebar-name">Product</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/categories">
                <span className="sidebar-name">Category</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/sub-categories">
                <span className="sidebar-name">SubCategory</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/posts">
                <span className="sidebar-name">Post</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/videos">
                <span className="sidebar-name">Video</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/comments">
                <span className="sidebar-name">Comments</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu">
          <div onClick={handleCollapse}>
            <img src={IconDownload} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name">B2B Database</span>
            <img src={IconArrow} alt="Home" className="sidebar-arrow" />
          </div>
          <ul className="submenu">
            <li>
              <Link to="/admin/b2b-databases/add">
                <span className="sidebar-name">Add List</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/b2b-databases/search">
                <span className="sidebar-name">Search</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/b2b-databases/settings">
                <span className="sidebar-name">Settings</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/b2b-databases/logs">
                <span className="sidebar-name">Logs</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu">
          <Link to="/admin/logs">
            <img src={IconData} alt="Home" className="sidebar-logo" />
            <span className="sidebar-name"> Logs </span>
          </Link>
        </li>
      </ul>
    </S.Nav>
  );
}

SideBarAdmin.propTypes = {
  toogle: PropTypes.bool.isRequired,
};

export default SideBarAdmin;
