export function Translate() {
  const language = JSON.parse(localStorage.getItem('language'));

  return {
    language,
    labelDataName: language === 'pt-BR' ? 'Nome da base *' : 'Data name',
    labelSector: language === 'pt-BR' ? 'Setor' : 'Sector',
    labelTecnology: language === 'pt-BR' ? 'Tecnologia' : 'Tecnology',
    labelEmployeesQty: language === 'pt-BR' ? 'Qte. de funcionários' : 'N. of employees',
    labelAnnualRevenue: language === 'pt-BR' ? 'Receita anual' : 'Annual Revenue',
    labelHeadquartersRegion: language === 'pt-BR' ? 'Região da sede' : 'Headquarters region',
    labelVerifiedData: language === 'pt-BR' ? 'Base veficada ' : 'Verified data',
    labelNotVerifiedData: language === 'pt-BR' ? 'Base não veficada' : 'Not verified data',
    optionSelect: language === 'pt-BR' ? 'Selecione' : 'Select',
    btnSubmit: language === 'pt-BR' ? 'Enviar' : 'Submit',
    msgLoading: language === 'pt-BR'
      ? 'O csv importado já passou pela avaliação inicial. O processo de inclusão dos dados pode levar alguns minutos.. Aguarde, por favor.'
      : 'The imported csv has already passed the initial evaluation. The data inclusion process may take a few minutes. Please wait.',
  };
}
