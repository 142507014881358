import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../../../context/AuthContext';

import TitlePage from '../../../../components/TitlePage';
import Square from '../../../../components/Square';
import { FunnelChart } from '../components/FunnelChart';
import { Table } from '../components/Table';

import { ContentContainer } from './style';

export function TrackerCompany() {
  const authContext = useContext(AuthContext);
  const { admin } = authContext.userInfo;

  if (admin === 'N') return <Navigate to="/client/not-allowed" />;

  return (
    <>
      <TitlePage title="RH Tracker" />
      <ContentContainer>
        <FunnelChart />
        <Square size="large">
          <Table />
        </Square>
      </ContentContainer>
    </>
  );
}
